(function($) {
  $(document).ready(function() {
    var input = $("input, textarea");

    input.each(function() {
      if ($(this).val() != "") {
        $(this)
          .closest(".input-parent")
          .addClass("input--active");
      } else {
        $(this)
          .closest(".input-parent")
          .removeClass("input--active");
      }
    });

    input.focus(function() {
      $(this)
        .closest(".input-parent")
        .addClass("input--active");
    });

    input.blur(function() {
      if ($(this).val() != "") {
        $(this)
          .closest(".input-parent")
          .addClass("input--active");
      } else {
        $(this)
          .closest(".input-parent")
          .removeClass("input--active");
      }
    });
  });
})(jQuery);
