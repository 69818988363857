var navToggle = document.querySelector(".js-nav-toggle");
var navSlider = document.querySelector(".js-global-nav");
var screen = window;

navToggle.addEventListener("click", function() {
  navToggle.classList.toggle("js-navopen");
  navSlider.classList.toggle("js-navopen");
});

screen.addEventListener("resize", function() {
  if (screen.innerWidth >= 992) {
    navToggle.classList.remove("js-navopen");
    navSlider.classList.remove("js-navopen");
  }
});

// var navToggle = document.querySelector(".js-nav-toggle");
// var nav = document.querySelector(".gh-nav");
// var navList = document.querySelector(".gh-nav");
// var screen = window;
// var body = document.body;

// navToggle.addEventListener("click", function() {
//   nav.classList.toggle("navopen");
//   body.classList.toggle("stopscroll");
//   navToggle.classList.toggle("navopen");
// });

// navList.addEventListener("click", function() {
//   nav.classList.remove("navopen");
//   navToggle.classList.remove("navopen");
// });
// screen.addEventListener("resize", function() {
//   if (screen.innerWidth >= 768) {
//     nav.classList.remove("navopen");
//     body.classList.remove("stopscroll");
//     navToggle.classList.remove("navopen");
//   }
// });
