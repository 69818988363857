var searchToggle = document.querySelector(".js-search-field-toggle");
var inputParent = document.querySelector(".js-search-field");

searchToggle.addEventListener("click", function() {
  inputParent.classList.toggle("js-search-field-active");

  if (searchToggle.classList.contains("icon-magnifier")) {
    searchToggle.classList.remove("icon-magnifier");
    searchToggle.classList.add("icon-close");
  } else {
    searchToggle.classList.add("icon-magnifier");
  }
});
